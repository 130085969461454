import React, { useState, useEffect } from "react";
import LineTopics from "../LineTopics";
import highlight from "../../../images/highlight.png";
import "./style.css";

const BackSlider = ({ children, slides, className }) => {
    const [remainingTime, setRemainingTime] = useState(slides[0]?.timeSecs);
    const [slideId, setSlideId] = useState({ current: 0, next: 1 });
    const [trigger, setTrigger] = useState(false);
    const [clicked, setClicked] = useState(-1);
    const nextSlide = (id = null) => {
        id = id ?? slideId.next;
        let aux = (id + 1) % slides.length;
        setSlideId({ current: id, next: aux });
        setRemainingTime(slides[slideId.current].timeSecs);
    };

    useEffect(() => {
        if (remainingTime > 0) {
            const interval = setInterval(() => {
                setRemainingTime(remainingTime - 1);
                setTrigger(remainingTime == 2);
            }, 1000);
            return () => clearTimeout(interval);
        } else {
            nextSlide();
            setClicked(-1);
        }
    }, [remainingTime]);

    return (
        <main
            className={`back-slider ${trigger ? "trigger" : ""} ${
                slides[slideId.current].filter > 0 ? "thanos" : ""
            } ${className} `}
            style={{
                "--bg1": `url("${slides[slideId.current].urlBg}")`,
                "--bg2": `url("${slides[slideId.next].urlBg}")`,
                "--highlight": `url("${highlight}")`,
                "--light": slides[slideId.current].filter,
            }}
        >
            <LineTopics topicId={slides[slideId.current].topicId} />
            {children}
            <footer>
                <span className="current">{slides[slideId.current].text}</span>
                <span className="next">{slides[slideId.next].text}</span>
                <nav>
                    {slides.map((index, ball) => (
                        <span key={ball}
                            onClick={() => {
                                setSlideId({ current: slideId.current, next: index });
                                setRemainingTime(2);
                                setClicked(index);
                            }}
                            className={`${slideId.current === index && clicked === -1 ? "this" : ""} ${
                                clicked === index ? "boom" : ""
                            }`}
                        />
                    ))}
                </nav>
            </footer>
        </main>
    );
};

export default BackSlider;
