import React from "react";
import circle1Off from "../../../images/login/icons/circle-1-off.png";
import circle2Off from "../../../images/login/icons/circle-2-off.png";
import circle3Off from "../../../images/login/icons/circle-3-off.png";
import circle1On from "../../../images/login/icons/circle-1-on.png";
import circle2On from "../../../images/login/icons/circle-2-on.png";
import circle3On from "../../../images/login/icons/circle-3-on.png";
import "./style.css";

const LineTopics = ({ topicId }) => {
    return (
        <figure className={`line  ${topicId !== null ? "render-" + topicId : "hide"}`}>
            <img src={topicId === 1 ? circle1On : circle1Off} alt="Vendemmia, ícone de navil" />
            <img src={topicId === 2 ? circle2On : circle2Off} alt="Vendemmia, ícone de caminhão" />
            <img src={topicId === 3 ? circle3On : circle3Off} alt="Vendemmia, ícone de armazém" />
            <svg
                id="Grupo_5"
                data-name="Grupo 5"
                xmlns="http://www.w3.org/2000/svg"
                width="108.832"
                height="1966"
                viewBox="0 0 108.832 1966"
            >
                <path
                    id="Caminho_1"
                    data-name="Caminho 1"
                    d="M11.58,0V438.589c0,16.777,4.115,32.861,11.442,44.725L93.851,598c8.132,13.167,12.7,31.022,12.7,49.638v597.443c0,18.616-4.568,36.471-12.7,49.638L11.942,1427.35C4.617,1439.214.5,1455.3.5,1472.075V1966"
                    transform="translate(0.892)"
                    fill="none"
                    stroke="#ff2a6a"
                    strokeWidth="6"
                />
            </svg>
        </figure>
    );
};

export default LineTopics;
