import React from "react";
import logo from "../../../images/login/icons/logo.png";
import "./style.css";

const Loading = ({ status = false }) => {
    return (
        <aside className={`loading ${status ? "start" : ""}`}>
            <figure>
                <img src={logo} alt="Vendemmia - Carregando..." />
            </figure>
        </aside>
    );
};
export default Loading;
