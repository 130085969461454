import React from "react";
import logo from "../../../images/logo.png";
import btnGoogle from "../../../images/google.png";
import btnApple from "../../../images/apple.png";
import "./style.css";

const CardRound = ({ children, hideMobile }) => {
    return (
        <section className="card-round">
            <figure className="logo">
                <img src={logo} alt="Logo Vendemmia" />
            </figure>
            {children}
            {!hideMobile && (
                <>
                    <figure className="mobile">
                        <figcaption>
                            <h3>Também disponível para:</h3>
                        </figcaption>
                        <a href="https://play.google.com/store/apps/details?id=br.com.topnode.vendemmia" target="_blanck">
                            <img src={btnGoogle} />
                        </a>
                        <a href="https://apps.apple.com/br/app/vendemmia/id1559775559" target="_blanck">
                            <img src={btnApple} />
                        </a>
                    </figure>
                    <a href="https://vendemmia.com.br/politica-de-privacidade-e-lgpd/" target="_blanck" className="policy">
                        Política de Privacidade
                    </a>
                </>
            )}
        </section>
    );
};

export default CardRound;
